.logo {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
}
