@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap);
html,body,#root{height:100%;width:100%;margin:0;padding:0}#root{background-color:#01040c}body{margin:0;font-family:"Comfortaa",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
.container {
    width: 100%;
    height: 100%;
}
.logo {
    position: absolute;
    top: 0;
    height: 10vmin;
    pointer-events: none;
    z-index: 1;
}

.logo {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-height: 100vh;
}

.App {
  text-align: center;
  background-color: #212943;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

iframe {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border: 0;
}
.App-header {
  background-color: #2fd6e8;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.container {
  width: 100%;
  height: 100vh;
  height: 100%; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: stretch;
  background-color: #01040c;
}

.loading-container{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.rollback-loading-logo{width:200px;height:auto;-webkit-animation:pulse 2s linear infinite;animation:pulse 2s linear infinite}@-webkit-keyframes pulse{0%{-webkit-transform:scale(1);transform:scale(1)}50%{-webkit-transform:scale(1.2);transform:scale(1.2)}100%{-webkit-transform:scale(1);transform:scale(1)}}@keyframes pulse{0%{-webkit-transform:scale(1);transform:scale(1)}50%{-webkit-transform:scale(1.2);transform:scale(1.2)}100%{-webkit-transform:scale(1);transform:scale(1)}}
.container{display:flex;align-items:center;justify-content:center;background-color:#000}.signout-button{align-self:flex-end}
.user-container{width:100vw;height:100vh;background-color:#000;color:#fff}.events-and-avatar{width:100vw;height:45vh;display:flex}.user-profile-avatar{display:flex;justify-content:flex-end;align-items:flex-start;width:50%;height:auto;margin-top:10px}.user-events{width:50%}.user-events>ul{list-style-type:none}.events-handlers{width:100vw;height:50vh;display:flex;justify-content:center;align-items:center}.events-handlers>button{cursor:pointer}
