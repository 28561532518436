.user-container {
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
}

.events-and-avatar {
  width: 100vw;
  height: 45vh;
  display: flex;
}

.user-profile-avatar {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 50%;
  height: auto;
  margin-top: 10px;

  //   > img {
  //     width: 50px;
  //     object-fit: contain;
  //   }
}

.user-events {
  width: 50%;
  > ul {
    list-style-type: none;
  }
}

.events-handlers {
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    cursor: pointer;
  }
}
