.container {
    width: 100%;
    height: 100%;
}
.logo {
    position: absolute;
    top: 0;
    height: 10vmin;
    pointer-events: none;
    z-index: 1;
}
